exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-devanture-tsx": () => import("./../../../src/pages/devanture.tsx" /* webpackChunkName: "component---src-pages-devanture-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-la-galerie-tsx": () => import("./../../../src/pages/la-galerie.tsx" /* webpackChunkName: "component---src-pages-la-galerie-tsx" */),
  "component---src-templates-page-artist-artworks-tsx": () => import("./../../../src/templates/PageArtistArtworks.tsx" /* webpackChunkName: "component---src-templates-page-artist-artworks-tsx" */),
  "component---src-templates-page-artist-tsx": () => import("./../../../src/templates/PageArtist.tsx" /* webpackChunkName: "component---src-templates-page-artist-tsx" */),
  "component---src-templates-page-childress-radi-tsx": () => import("./../../../src/templates/PageChildressRadi.tsx" /* webpackChunkName: "component---src-templates-page-childress-radi-tsx" */),
  "component---src-templates-page-default-tsx": () => import("./../../../src/templates/PageDefault.tsx" /* webpackChunkName: "component---src-templates-page-default-tsx" */),
  "component---src-templates-page-exhibition-tsx": () => import("./../../../src/templates/PageExhibition.tsx" /* webpackChunkName: "component---src-templates-page-exhibition-tsx" */),
  "component---src-templates-page-listing-artist-tsx": () => import("./../../../src/templates/PageListingArtist.tsx" /* webpackChunkName: "component---src-templates-page-listing-artist-tsx" */),
  "component---src-templates-page-listing-exhibition-tsx": () => import("./../../../src/templates/PageListingExhibition.tsx" /* webpackChunkName: "component---src-templates-page-listing-exhibition-tsx" */),
  "component---src-templates-page-listing-press-review-tsx": () => import("./../../../src/templates/PageListingPressReview.tsx" /* webpackChunkName: "component---src-templates-page-listing-press-review-tsx" */),
  "component---src-templates-page-listing-publication-tsx": () => import("./../../../src/templates/PageListingPublication.tsx" /* webpackChunkName: "component---src-templates-page-listing-publication-tsx" */),
  "component---src-templates-page-press-review-tsx": () => import("./../../../src/templates/PagePressReview.tsx" /* webpackChunkName: "component---src-templates-page-press-review-tsx" */)
}

