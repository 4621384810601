import React, {useState} from "react"
import {Link, PageProps} from "gatsby"
import LogoMdSvg from "../../assets/logo-gbj-md.svg"
import WebsiteJsonLD from "./WebsiteJsonLD"

const LogoLink = () =>
<Link to={"/"} className={`logo-link`}>
    <img src={LogoMdSvg as string} width={378} height={72} />
</Link>


export default function HeaderNav({location}:Partial<PageProps>){

    const [menuVisible, setMenuVisible] = useState(false);
    function onClick(){
        setMenuVisible(!menuVisible);
    }

    const isHome = location?.pathname === '/';
    const siteTitleProps = {
        className:"site-title",
        role:"heading"
    }

    return <header className="header">
        <WebsiteJsonLD />
        <div className="header__fixed">
            <div className="header__container" id={"site-header"}>
                {isHome ? <h1 {...siteTitleProps}><LogoLink /></h1> :
                    <div {...siteTitleProps}><LogoLink /></div> }

                <nav>
                    <Link to={"/expositions/"}>Expositions</Link>{' '}
                    <Link to={"/artistes/"}>Artistes</Link>{' '}
                    <div className={`burger__menu ${menuVisible?'visible':''}`}>
                        <div className="container">
                            <div className={"site-title"}><LogoLink /></div>
                            <ul>
                                <li><Link to={"/"} activeClassName={'active'} onClick={onClick}>En ce moment</Link></li>
                                <li><Link to={"/artistes/"} activeClassName={'active'} onClick={onClick}>Artistes</Link></li>
                                <li><Link to={"/expositions/"} activeClassName={'active'} onClick={onClick}>Expositions</Link></li>
                                <li><Link to={"/publications/"} activeClassName={'active'} onClick={onClick}>Publications</Link></li>
                                <li><Link to={"/revues-de-presse/"} activeClassName={'active'} onClick={onClick}>Presse</Link></li>
                                <li><Link to={"/la-galerie/"} activeClassName={'active'} onClick={onClick}>La galerie</Link></li>
                            </ul>
                        </div>
                    </div>
                    <button className="burger__button" type={"button"} aria-label={"menu"} aria-expanded={menuVisible} onClick={onClick} >
                        <span /><span /><span /><span />
                    </button>
                </nav>
            </div>
        </div>
    </header>
}