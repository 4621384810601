import React, {PropsWithChildren, ReactElement} from "react"
import "../../styles/main.scss"
import HeaderNav from "./HeaderNav"
import {Component, createContext} from "react";
import {
    RenderBodyArgs,
    PluginOptions,
    PageProps,
    WrapPageElementBrowserArgs,
    WrapPageElementNodeArgs,
    WrapRootElementBrowserArgs, WrapRootElementNodeArgs
} from "gatsby";
import {PageContext} from "gatsby/internal";
import {WindowLocation} from "@reach/router";

export const DisplayFrontage = createContext('yes');

export function onRenderBody({setHeadComponents,setHtmlAttributes,setPostBodyComponents}:RenderBodyArgs, pluginOptions:PluginOptions){
    setHtmlAttributes({lang:'fr'})
    setHeadComponents([
        <script key={"matomo"} dangerouslySetInnerHTML={{
            __html:`var _paq = window._paq = window._paq || [];
            _paq.push(['trackPageView']);
            _paq.push(['enableLinkTracking']);
            (function() {
            var u="//matomo.galeriebernardjordan.com/matomo/";
            _paq.push(['setTrackerUrl', u+'matomo.php']);
            _paq.push(['setSiteId', '1']);
            var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
            g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
        })();`
        }} />,
        <link
            key={`sitemap-index`}
            rel="sitemap"
            type="application/xml"
            href={`//www.galeriebernardjordan.com/sitemap.xml`}
        />
    ])
    setPostBodyComponents([
        <div key={"portal"} id={"portal"} />
    ])
}

class Tracker extends Component<{pageContext:PageContext,location:WindowLocation<any>}> {

    getDataLayer(){
        const {props} = this;
        const {pageContext,location} = props;
        const title = document?.title || '-';
        let template = pageContext.template || location.pathname.replace(/^\/(.*)\/?$/,'$1')
        if (template === ''){ template = 'home'; }
        return {
            pathname:location.pathname,
            title:title,
            template:template
        }
    }

    track(pageView:boolean){
        const _paq:[...any][] = ({_paq:[],...window})._paq;
        if(!!_paq){
            const {title,template,pathname} = this.getDataLayer()
            _paq.push(['setCustomVariable', 1, 'Template', template]);
            _paq.push(['setDocumentTitle', title]);
            if(pageView){
                _paq.push(['setCustomUrl', pathname]);
                _paq.push(['trackPageView']);
            }
        }
    }

    componentDidMount() {
        this.track(false);
    }

    // @ts-ignore
    componentDidUpdate(prevProps, prevState, snapshot) {
        this.track(!!prevProps);
    }
    render(){
        return <></>
    }
}

export default function MainLayout({ children, location, pageContext }: PropsWithChildren<PageProps>){
    return (
        <div className={`layout--main__wrapper`}>
            <div className={`layout--main`}>
                <HeaderNav pageContext={pageContext} location={location} />
                <Tracker pageContext={pageContext} location={location} />
                <main className="main">{children}</main>
            </div>
        </div>
    )
}

export function wrapPageElement({element,props}: WrapPageElementNodeArgs | WrapPageElementBrowserArgs){
    // @ts-ignore
    return <MainLayout {...props}>{element}</MainLayout>;
}

export function onClientEntry(){
}

export function onInitialClientRender(){
}

export function wrapRootElement({ element }:WrapRootElementBrowserArgs|WrapRootElementNodeArgs){
    return element
}