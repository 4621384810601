
import {useStaticQuery,graphql} from "gatsby"
import HelmetJsonLD from "../formatter/HelmetJsonLD"
import React from "react";

export default function WebsiteJsonLD() {

    const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          siteDesription:description
          siteUrl
          siteLang
          siteTitle:title
        }
      }
      frontage: wpPage(slug: {eq: "frontage"}) {
        featuredImage {
          node {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `)

    const ogImage = data.frontage.featuredImage?.node?.localFile?.publicURL || '';
    const {siteUrl = '', siteDescription = '', siteTitle = ''} = data?.site?.siteMetadata || {};

    return <HelmetJsonLD>{{
        "@context": "http://schema.org",
            "@type": "WebSite",
            "url": siteUrl,
            "name": siteTitle,
            "description": siteDescription,
            "image": `${siteUrl}${ogImage}`,
    }}</HelmetJsonLD>
}